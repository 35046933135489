import React from "react"
import { Navbar, Container, Nav, Button } from "react-bootstrap"
import Logo from "../../images/logo.png"
import { FaLinkedin } from "react-icons/fa"

export default function NavigationBar() {
  return (
    <>
      <Navbar bg="light" variant="light" expand="lg">
        <Container>
          <Navbar.Text>Connecting dots in the digital world</Navbar.Text>
          <Nav>
            <Nav.Link href="mailto:info@symbiot-am.com">
              info@symbiot-am.com
            </Nav.Link>
            <Nav.Link
              className="hide-on-mobile"
              target="_blank"
              href="https://www.linkedin.com/company/symbiot-am/"
            >
              <FaLinkedin size={20} />
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <img src={Logo} alt="symbiot-am logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="symbiot-navbar" />
          <Navbar.Collapse id="symbiot-navbar" className="justify-content-end">
            <Nav className="ml-auto">
              <Nav.Link className="nav-link-color" href="/#home">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-color" href="/#about">
                About
              </Nav.Link>
              <Nav.Link className="nav-link-color" href="/#tech">
                Our Partners
              </Nav.Link>
              <Button className="nav-btn" href="/#contact" variant="secondary">
                Contact Us
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}
