import React from "react"
import { Container, Nav, Navbar } from "react-bootstrap"
import Logo from "../../images/logo.png"

const date = new Date()
let year = date.getFullYear()

export default function Footer() {
  return (
    <div className="bg-dark">
      <Navbar fluid className="bg-dark py-4">
        <Container className="text-success">
          <Navbar.Brand>
            <img src={Logo} alt="symbiot logo" />
          </Navbar.Brand>
          <Nav className="flex-column text-center hide-on-mobile">
            <h5>Navigation</h5>
            <Nav.Link className="footer-links" href="#home">
              Home
            </Nav.Link>
            <Nav.Link className="footer-links" href="#about">
              About
            </Nav.Link>
            <Nav.Link className="footer-links" href="#tech">
              Our Partners
            </Nav.Link>
            <Nav.Link className="footer-links" href="/privacy">
              Privacy Policy
            </Nav.Link>
          </Nav>
          <Nav className="text-center flex-column">
            <h4>United Arab Emirates</h4>
            <h4>United Kingdom</h4>
            <h4>Australia</h4>
            <h4>United States of America</h4>
          </Nav>
        </Container>
      </Navbar>
      <Container fluid className="bg-light">
        <p className="m-0 text-center text-dark">
          Copyright &copy; {year} - Symbiot-AM
        </p>
      </Container>
    </div>
  )
}
